.contact-us-form{
  margin-top: 30px;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}
.contact-us-content{
  display: grid;
  grid-template-columns: 70% 30%
}
.company-details{
  padding-top: 10%;
}
  @media screen and (max-width: 600px) {
    .contact-us-content{
      display: grid;
      grid-template-columns: 100%
    }
    .company-details{
      padding-left: 5%;
    }
  
}