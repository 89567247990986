

.footer-new-container{
    border: 2px solid;
    color: white;
    background: #568c11;
}
.footer-lists{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: start;
}
.footer-div a:hover{
    font-weight: bold;
}
.footer-div{
    margin: 25px 0 0 20px;
}
.footer-list-heading{
    font-size: 20px;
    padding-left: 15px;
    border-left: 4px solid;
    margin-bottom: 10px;
    font-weight: bold;
}

.contact-icon-cntainer i {
    margin: 10px;
    font-size: 20px;

}

.footer-div a {
    font-weight: 200;
}
.footer-address {
    font-size: .9rem;
    font-weight: 200;
}
.footer-grass{
    width:200%;
    margin-top:-35px
}

@media screen and (max-width: 600px) {
    .footer-lists{
        grid-template-columns: 1fr;
        text-align: start;
    }
    .footer-div{
        margin: 25px 0 0 30px;
    }
    .footer-grass{
        width:400%;
        margin-top:-5px;
    }
}