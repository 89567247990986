body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
    box-sizing: border-box;
    
}
body {
    overflow-x: hidden;
}
.upper-header{
    display:flex;
    height: 35px;
    background-color:#568c11;
}
.header-menu-list-container{
    width:70%;
    display:flex;
    font-weight: 600;
    justify-content: flex-end;
}
.logo-container{
    width:20%;
}
.menu-list-item a{
    color:black;
    padding:2px;
}
.menu-list-item a:hover{
    font-weight: bold;
    border-radius: 10%;
    color:#568c11;
}
.dropbtn {
    background: white;
    color: black;
    padding: 20px 20px 20px 0;
    font-size: .9em;
    font-weight: 600;
    border: none;
    text-align: left
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: green; color: white}
  
  .dropdown:hover .dropdown-content {display: block;}

.heading-options{
    display:none;
}

.product-dev-container{
    text-align: start;
    margin: 20px;
}
#menu-image{
    display:none;
}
.menu-dropdown{
    display: none;
}
.logo-container img{
    width:100%;
    height:65px;
    margin-top:5px;
}
.header-main-div{
    position: fixed;
z-index: 1;
width: 100%;
top: 0;
background: white;
}
.header-2{
    height: 110px;
}
@media only screen and (max-width:750px) {
    .upper-header{
        display:none;
    }
    .header-main-div{
        height:100px;
    }
    .header-2{
        height: 100px;
    }
    .lower-header{
        position: relative;
        height:100%;
        flex-wrap:wrap;
        z-index: 1;
        background:white;
        width:100vw;
    }
    .logo-container img {
        width:75%;
    }
    .menu-dropdown{
        display: block;
        height:auto;
        width: 200px;
        border: 1px solid black;
        background-color: whitesmoke;
        position: relative;
        margin-top:3px;
        z-index: 1;
    }
    .header-menu-list-container{
        width:10%;
        height:40px;
        border: 1px solid black;
        position: absolute;
        left:0;
        top:100%;
        padding:3px;
        overflow: hidden;
        display: inline-block;
        background:white;
    }
    .menu-list-item{
        display: none;
    }
    .dropdown{
        display: none;
    }
    .logo-container{
        width:100%;
        height:100%;
        padding:5px;
        border: 1px solid rgb(235, 232, 232)
    }
    .meobile-menu-container{
        height:60px;
        border: 1px solid blue;
    }
    #menu-image{
        display: block;
        width:100%;
        height:100%;
        position: relative;
        top:0;
        left:0;
        padding:5px;
    }
    .header-menu-list-container:hover{
        overflow: visible;
    }

    .menu-dropdown a {
        color: black;
        padding: 10px 10px;
        text-decoration: none;
        display: block;
        font-size: 18px;
        font-weight: bold;
    }
    .menu-dropdown a:hover {background-color: green; color: white}
  
}

body{
    font-family: 'Roboto', sans-serif
}
.homepage-container{
	width:100vw;
	height:auto;
	position: relative;
}
.carousel{
    height:80vh;
}
.carousel-inner{
    height:100%;
}
.carousel-item img{
	width:100%;
    height:100%;
}
.d-block{
    width:100%;
    height:100%;
}

.carousel-control-next-icon{
	position: relative;
	right:-20px;
}

.carousel-control-prev-icon{
	position: relative;
	left:-20px;
}
.mobile-homepage{
    display: none;
}
.about-service-container{
    width:100%;
    height:35vh;
    /* background: whitesmoke; */

}
.isoflexcon{
    justify-content: space-around;

}
.iso-test2{
    width: 120px;
    margin-top: 15%;
}
.infra-con{
    margin-left: 10%;
}
.infra1-container{
    display: grid;
    grid-template-columns: 40% 60%;
    margin: 5px;
}
.infra1-container img{
    width: 90vh;
    height: 55vh;
}
.infra1-container div{
    font-size: xx-large;
    text-align: center;
    vertical-align: middle;
    line-height: 400px;

}
.infra2-container{
    display: grid;
    grid-template-columns: 60% 40%;
}
.infra2-container img{
    width: 90vh;
    height: 55vh;
}
.infra2-container div{
    font-size: xx-large;
    text-align:center;
    vertical-align: middle;
    line-height: 400px;
}
.service-heading{
    width: 40%;
    position: relative;
    left:30%;
    height:27%;
    color:grey;
    padding-top: 10px;
    margin-top: 10px;
}
.service-heading h4{
    font-weight: 10;
    color:rgb(70, 69, 69);
}
.star-container{
    width:10%;
    height:20px;
    position: absolute;
    left:45%;
    top:40%;
}
.star{
    width:20%;
    height:65%;
    margin:10%;
    border-radius:45%;
    background: green;
}
.service-para-container{
    width:100%;
    height:20%;
    padding-left:8%;
    padding-right:8%;
}
.service-para{
    font-size: 14px;
    color:rgb(75, 74, 74) ;
    font-weight: 100;  
    letter-spacing: 0.8px;
    word-spacing: 2px;
}
.product-and-service-container{
    width:100%;
    height:118vh;
    background: whitesmoke;
    flex-wrap: wrap;
    position: relative;
}
.product-container{
    width:100%;
    height:100%;
    overflow: hidden;
}
.event-and-news-container{
    width:30%;
    height:100%;
    overflow: hidden;
}
.feature-type{
	height:300px;
	width:32%;
    padding: 5px 30px 5px 35px;
    margin:5px;
    float: left;
}
.feature-type a{
    height:50%;
    padding-top:5%;
    color:black;
    text-decoration: none
}
.feature-type a:hover{
    color:green;
}

.feature-link-div{
	width:100%;
	height:70%;
    background-color: whitesmoke;
}

.feature-type .card{
	width:100%;
	height:100%;
	position: relative;
    border:none;
    background: transparent;
    margin-top:15%;
}
.feature-type .card .card-image{
	height:80%;
    padding:2px;
    margin-top:-38%;
    overflow: hidden;
    box-shadow: 3px 3px 10px 5px grey;
}
.container-heading{
    width: 40%;
    position: relative;
    left:30%;
    height:80px;
    padding-top: 10px;
}
.container-heading h4{
    font-weight: 10;
    color:rgb(70, 69, 69);
}
.star-container{
    width:10%;
    height:20px;
    position: absolute;
    left:45%;
    top:43%;
}
.line-container_1{
    width:25%;
    position: absolute;
    left:21%;
    top:55%;
    border:0.5px solid green;
}
.line-container_2{
    width:25%;
    position: absolute;
    right:22%;
    top:55%;
    border:0.5px solid green;
}
.feature-type .card .card-image img{
	height:100%;
	width:100%;
}

.event-service-heading{
    width: 100%;
    height:50%;
    padding-top: 10px;
    margin-top: 10px;
}
.event-service-heading h4{
    font-weight: 10;
    color:rgb(83, 82, 82);
}
.card-img-top{
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
}

.card-img-top:hover { /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
    opacity: 0.5;
    
  }
.testimonials-container{
    width:20%;
    height:45vh;
    margin:25px;
    position: relative;
    overflow: hidden;
    background-color:white;
    box-shadow: 3px 3px 10px 5px grey;
}
.test-bg img{
    width:100%;
    height:100%;
}
.name-container{
    width:100%;
    height:25%;
    border: 3px solid rgb(240, 238, 238);
    padding:6px;
    overflow: hidden;
    border:5px solid green;
}
.testimonials-symbol{
    width:20%;
    height:100%;
    border:3px solid rgb(95, 138, 67);
    padding:3px;
}
.child-symbol{
    width:100%;
    height:100%;
    border:3px solid rgb(95, 138, 67);
    background: #98a86d;
}
.name-heading{
    padding:2px;
}
.name-heading h5{
    font-weight:100;
    font-size:16px;
    color:black;
    margin: 7px 10px 5px 20px;
}
.testimonilas-image img {
    height:100%;
    width:100%;
    display: block;
    overflow: hidden;
}
.message-container{
    width:100%;
    height:75%;
    border: 3px solid rgb(240, 238, 238);
    overflow: hidden;
    padding:10px;
    border:5px solid green;
}
.message-container h5{
    font-weight:100;
    font-size:16px;
    color:black;
    margin: 7px 10px 5px 20px;
    letter-spacing: 0.8px;
    word-spacing: 2px;
    text-align: unset;
}
.testimonilas-image{
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
}

.testimonilas-image:hover { /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}
.testimonials-container{
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
}
.testimonials-container:hover { /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.1); 
    
  }

/*
*  Research and Event file css
*/

.research-and-event-container{
    width:100vw;
    height:80vh;
}
.research-and-event-inner-container{
    width:100%;
    height:80%;
}
.research-container{
    width: 50%;
    height:100%;
    padding:5px 5% 5px 5%;
    border-right: 2px solid rgb(146, 143, 143)
}
.event-container{
    width:50%;
    height:100%;
    padding:5px 5% 5px 5%;
    position: relative;
}
.event-component-container{
    width:100%;
    height: 27%;
    border: 1px solid whitesmoke;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    margin-right:10px;
    margin-bottom: 5px;
}
.event-component-container-project{
    width:100%;
    height: 112px;
    border: 1px solid whitesmoke;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    margin-right:10px;
    margin-bottom: 5px;
    position: relative;
}
.event-component-container-project:hover{
    background: #cccccc;
}
.event-image-container{
    width:30%;
    height:100%;
    position: relative;
}
.event-image-container img{
    height:100%;
    width:100%;
}
.event-summary-container{
    width:70%;
    height:100%;
    padding: 1% 5% 1% 5%;
}
.event-discription-container{
    height:30%;
    text-align: justify;
}
.popular-event-component-container{
    width:48%;
    height: 48%;
    border: 1px solid whitesmoke;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    margin-right:10px;
    flex-wrap: wrap;
}
.news-event-update-container{
    height:65vh;
    /* background: whitesmoke; */
}

.Certifications-container{
    height:40vh;
    /* background: whitesmoke; */
}

.news-content-container{
    height:72%;
    padding-left:2%;
    max-width: 100%;
    overflow-x: auto;

}
.news-content-container .card {
    height: 100%;
    min-width: 260px;  
}
.news-content-container .card .card-img-top {
    height:70%;
}
.popular-products{
    height:80vh;
    width:100%;
    flex-wrap: wrap;
    padding:0 2% 4% 2%;
}
.popular-products-container{
    height:100%;
    width:100%;
    flex-wrap: wrap;
    padding:1% 1% 5% 3%;
}
.popular-products-container .event-component-container{
    width:50%;
    height: 45%;
    border: 1px solid whitesmoke;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    margin:0;
}

.container-slider {
    position: relative;
    /* margin: 0 auto; */
  }
  
  .container-slider .content-slider {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
  }
  /* Darker background on mouse-over */
  .btn:hover {
    background-color: #196E3E;
  }
  .mobile-testimonials-container{
    display: none;
}
.testimonials-content-container{
    display: flex;
    padding:3%;
  }
  .testimonials-outer-container{
    height:80vh;
    position:relative
  }
  .test-bg{
    position:absolute;
    width:100%;
    height:100%;
  }
  .test-bg img{
      display: block;
    width:100%;
    height:100%;
  }

  @media only screen and (max-width: 600px) {
      body{
          overflow-x: hidden;
      }
      .carousel-inner{
          display: none;
      }
      .carousel{
          width:100vw;
          height: 50vh;
      }
      .carousel img{
          width:100%;
          height:100%;
      }
      .about-service-container{
        width:100%;
        height:auto;
    }
    .star-container{
        width:10%;
        height:20px;
        position: absolute;
        left:45%;
        top:38px;
    }
    .line-container_1{
        width:35%;
        position: absolute;
        left:40px;
        top:48px;
    }
    .line-container_2{
        width:35%;
        position: absolute;
        right:40px;
        top:48px;
    }
    .star{
        width:20%;
        height:65%;
        margin:10%;
        border-radius:45%;
    }
      .service-heading{
          width:100%;
          text-align: center;
          position: relative;
          left:2%;
          height:40%;
          color:grey;
          padding-top: 10px;
          margin-top: 10px;
      }
      .service-para-container{
        width:100%;
        height:10%;
        padding-left:2%;
        padding-right:2%;
        margin-top:20px;
    }
    .service-para{
        height:auto;
        width:100%;
        font-size: 14px;
        color:rgb(75, 74, 74) ;
        font-weight: 100;  
        text-align: justify;
        letter-spacing: 0.8px;
        word-spacing: 2px;
    }
    .mobile-homepage{
        display: block;
    }
    .container-heading{
        width: 100%;
        position: relative;
        left:2%;
        height:50px;
        padding-top: 10px;
    }
    .feature-type{
        width:98%;
        height:14%;
        float: none;
        margin-bottom: 10px;
        padding:15px 20px 15px 10px;
    }
    .feature-type .card{
        height:300px;
        overflow: visible;
        margin-top:0;
    }
    .feature-type .card .card-image{
        overflow: visible;
        width:100%;
        height:80%;
        margin-top:0;
        box-shadow: 3px 3px 10px 5px grey;
    }
    .feature-type .card .card-body{
        width:100%;
        height:20%;
    }
    .card-title{
        width: 100%;
        height:30%;
    }

    .product-and-service-container{
        height:auto;
        overflow: visible;
    }
    /* .product-container{
        height:auto;
        overflow: visible;
        display: block;
    } */
    /*
        * research and project css
    */

    .research-and-event-container{
        height:auto;
        border: 1px solid red;
        position: relative;
    }
    .research-and-event-inner-container{
        flex-wrap: wrap;
        width:100%;
        height:auto;
        display: block;
        position: relative;
    }
    .research-container{
        width:100%;
        height: auto;
    }
    .event-container{
        width:100%;
        height:auto;
    }
    .event-component-container{
        width:100%;
        height: 150px;
        margin-bottom:10px;
    }
    .event-component-container-project{
        width:100%;
        height: 150px;
        margin-bottom:10px;
    }

    /**
     `* testimonials css
    */
    .testimonials-outer-container{
        height:80vh;
        width:100%;
        display: block;
        position: relative;
        padding:3% 5% 3% 3%;
    }
    .testimonials-content-container{
      display: none;
    }
    .testimonials-container{
       display: none;
    }
    .event-image-container{
        height:50%;
        margin-top: 25px;
    }
    .mobile-testimonials-container{
        display: block;
        height:auto;
        width:100%;
        background: white;
        position: relative;
        top:20px;
    }
    .mobile-testimonials-content{
        height:15vh;
        border: 1px solid green;
        width:100%;
        padding:7px;
    }
    .test-bg{
        display: none;
    }
    .news-event-update-container{
        position: relative;
    }
    .our-research-img{
        margin-top: 25px;
    }
    .isoflexcon{
        flex-wrap: wrap;
    }
    .iso-test2{
        width: 150px;
        margin-top: 5%;
    }
    .Certifications-container{
        height:95vh;
    }
    .infra1-container{
        display: grid;
        grid-template-columns: 30% 70%;
        margin: 5px;
    }
    .infra1-container img{
        width: 28vh;
        height: 20vh;
    }
    .infra1-container div{
        font-size: 7px;
        text-align: center;
        vertical-align: middle;
        line-height: 100px;
    }
    .infra2-container{
        display: grid;
        grid-template-columns: 30% 70%;
        margin: 5px;
    }
    .infra2-container img{
        width: 28vh;
        height: 20vh;
    }
    .infra2-container div{
        font-size: 7px;
        text-align: right;
        vertical-align: middle;
        line-height: 100px;
    }
  }


.footer-new-container{
    border: 2px solid;
    color: white;
    background: #568c11;
}
.footer-lists{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: start;
}
.footer-div a:hover{
    font-weight: bold;
}
.footer-div{
    margin: 25px 0 0 20px;
}
.footer-list-heading{
    font-size: 20px;
    padding-left: 15px;
    border-left: 4px solid;
    margin-bottom: 10px;
    font-weight: bold;
}

.contact-icon-cntainer i {
    margin: 10px;
    font-size: 20px;

}

.footer-div a {
    font-weight: 200;
}
.footer-address {
    font-size: .9rem;
    font-weight: 200;
}
.footer-grass{
    width:200%;
    margin-top:-35px
}

@media screen and (max-width: 600px) {
    .footer-lists{
        grid-template-columns: 1fr;
        text-align: start;
    }
    .footer-div{
        margin: 25px 0 0 30px;
    }
    .footer-grass{
        width:400%;
        margin-top:-5px;
    }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


  .product-heading-container {
    background: #568c11; /* Fallback color */
    background-image: url(/static/media/hbg1.823ff40c.jpg);
    color: #f1f1f1;
    width: 100%;
    padding: 15px 68px;
    text-align: start;
    display: flex;
    justify-content: space-between;
  }
.product-inquiry{
  border: 2px solid;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.product-inquiry:hover{
  background: rgb(66, 153, 66);
  border: 2px solid black;
  
}
.map {
  height: 300px;
}
  .button {
    /* width: 250px; */
    padding: 8px 0px 8px 0px;
    margin: 10px;
  }

  .active{
    background: #568c11;
    color: white;
    border-radius: 10px;
    font-weight: bolder;
  }

  .product-tablename-container{
    background: #f1f1f1;
    display : flex;
    justify-content: space-around;
    margin: 10px 0 10px 0;  
  }
 

  @media only screen and (max-width:750px) {
    .product-tablename-container{
      display: none;
    }
    .popular-product-img{
      width: 100%
    }
    .product-heading-container div h2 {
      font-size: 25px;
      margin-top: 13px;
    }
    .product-heading-container{
      justify-content: space-evenly;
      padding: 20px
    }
    
  }
*{
    box-sizing: border-box;
}
.input-field-container{
    width:45%;
    height:55px;
    padding:10px 
}
.contact-us-form{
  margin-top: 30px;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}
.contact-us-content{
  display: grid;
  grid-template-columns: 70% 30%
}
.company-details{
  padding-top: 10%;
}
  @media screen and (max-width: 600px) {
    .contact-us-content{
      display: grid;
      grid-template-columns: 100%
    }
    .company-details{
      padding-left: 5%;
    }
  
}
.about-us-container{
    padding:1% 3% 3% 3%;
    background: whitesmoke
}
.about-us-heading{
    font-weight:700;
    text-align: left;  
    margin: 10px 0 20px 0;
}
.about-us-para{
    width:100%;
    font-size: 14px;
    text-align: justify;
}
.buisness-develeopement-container{
    padding: 1% 3% 3% 3%;
    height: 240vh;
    background: whitesmoke
}
.buisness-dev-heading{
    margin: 10px 0 20px 0;
}
.buisness-dev-para{
    width:100%;
    font-size: 14px;
    text-align: justify;
}
.business-heading{
    text-align: left;
    font-weight: 700;
}
.buisness-dev-list{
    width:100%;
    position: relative;
}
.buisness-dev-list-item{
    text-align: left;
    font-size: 14px;
}
.about-news-and-events{
    padding: 1% 3% 3% 3%;
    height: auto;
    background: whitesmoke
}
.news-events-heading{
    margin: 10px 0 20px 0;
    text-align: left;
}
.common-para{
    width:100%;
    font-size: 14px;
    text-align: justify;
}
.common-unorder-list{
    width:30%;
    position: relative;
    left:0;
}
.common-unorder-list-item{
    text-align: left;
    font-size: 14px;
}

@media only screen and (max-width:600px) {
    .about-us-heading{
        text-align: center;  
    }
    .business-heading{
        text-align: center;
    }
    .buisness-develeopement-container{
        padding: 1% 3% 3% 3%;
        height: 100vh;
        background: whitesmoke
    }
  }
