
  .product-heading-container {
    background: #568c11; /* Fallback color */
    background-image: url('./hbg1.jpg');
    color: #f1f1f1;
    width: 100%;
    padding: 15px 68px;
    text-align: start;
    display: flex;
    justify-content: space-between;
  }
.product-inquiry{
  border: 2px solid;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.product-inquiry:hover{
  background: rgb(66, 153, 66);
  border: 2px solid black;
  
}
.map {
  height: 300px;
}
  .button {
    /* width: 250px; */
    padding: 8px 0px 8px 0px;
    margin: 10px;
  }

  .active{
    background: #568c11;
    color: white;
    border-radius: 10px;
    font-weight: bolder;
  }

  .product-tablename-container{
    background: #f1f1f1;
    display : flex;
    justify-content: space-around;
    margin: 10px 0 10px 0;  
  }
 

  @media only screen and (max-width:750px) {
    .product-tablename-container{
      display: none;
    }
    .popular-product-img{
      width: 100%
    }
    .product-heading-container div h2 {
      font-size: 25px;
      margin-top: 13px;
    }
    .product-heading-container{
      justify-content: space-evenly;
      padding: 20px
    }
    
  }