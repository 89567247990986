body{
    font-family: 'Roboto', sans-serif
}
.homepage-container{
	width:100vw;
	height:auto;
	position: relative;
}
.carousel{
    height:80vh;
}
.carousel-inner{
    height:100%;
}
.carousel-item img{
	width:100%;
    height:100%;
}
.d-block{
    width:100%;
    height:100%;
}

.carousel-control-next-icon{
	position: relative;
	right:-20px;
}

.carousel-control-prev-icon{
	position: relative;
	left:-20px;
}
.mobile-homepage{
    display: none;
}
.about-service-container{
    width:100%;
    height:35vh;
    /* background: whitesmoke; */

}
.isoflexcon{
    justify-content: space-around;

}
.iso-test2{
    width: 120px;
    margin-top: 15%;
}
.infra-con{
    margin-left: 10%;
}
.infra1-container{
    display: grid;
    grid-template-columns: 40% 60%;
    margin: 5px;
}
.infra1-container img{
    width: 90vh;
    height: 55vh;
}
.infra1-container div{
    font-size: xx-large;
    text-align: center;
    vertical-align: middle;
    line-height: 400px;

}
.infra2-container{
    display: grid;
    grid-template-columns: 60% 40%;
}
.infra2-container img{
    width: 90vh;
    height: 55vh;
}
.infra2-container div{
    font-size: xx-large;
    text-align:center;
    vertical-align: middle;
    line-height: 400px;
}
.service-heading{
    width: 40%;
    position: relative;
    left:30%;
    height:27%;
    color:grey;
    padding-top: 10px;
    margin-top: 10px;
}
.service-heading h4{
    font-weight: 10;
    color:rgb(70, 69, 69);
}
.star-container{
    width:10%;
    height:20px;
    position: absolute;
    left:45%;
    top:40%;
}
.star{
    width:20%;
    height:65%;
    margin:10%;
    border-radius:45%;
    background: green;
}
.service-para-container{
    width:100%;
    height:20%;
    padding-left:8%;
    padding-right:8%;
}
.service-para{
    font-size: 14px;
    color:rgb(75, 74, 74) ;
    font-weight: 100;  
    letter-spacing: 0.8px;
    word-spacing: 2px;
}
.product-and-service-container{
    width:100%;
    height:118vh;
    background: whitesmoke;
    flex-wrap: wrap;
    position: relative;
}
.product-container{
    width:100%;
    height:100%;
    overflow: hidden;
}
.event-and-news-container{
    width:30%;
    height:100%;
    overflow: hidden;
}
.feature-type{
	height:300px;
	width:32%;
    padding: 5px 30px 5px 35px;
    margin:5px;
    float: left;
}
.feature-type a{
    height:50%;
    padding-top:5%;
    color:black;
    text-decoration: none
}
.feature-type a:hover{
    color:green;
}

.feature-link-div{
	width:100%;
	height:70%;
    background-color: whitesmoke;
}

.feature-type .card{
	width:100%;
	height:100%;
	position: relative;
    border:none;
    background: transparent;
    margin-top:15%;
}
.feature-type .card .card-image{
	height:80%;
    padding:2px;
    margin-top:-38%;
    overflow: hidden;
    box-shadow: 3px 3px 10px 5px grey;
}
.container-heading{
    width: 40%;
    position: relative;
    left:30%;
    height:80px;
    padding-top: 10px;
}
.container-heading h4{
    font-weight: 10;
    color:rgb(70, 69, 69);
}
.star-container{
    width:10%;
    height:20px;
    position: absolute;
    left:45%;
    top:43%;
}
.line-container_1{
    width:25%;
    position: absolute;
    left:21%;
    top:55%;
    border:0.5px solid green;
}
.line-container_2{
    width:25%;
    position: absolute;
    right:22%;
    top:55%;
    border:0.5px solid green;
}
.feature-type .card .card-image img{
	height:100%;
	width:100%;
}

.event-service-heading{
    width: 100%;
    height:50%;
    padding-top: 10px;
    margin-top: 10px;
}
.event-service-heading h4{
    font-weight: 10;
    color:rgb(83, 82, 82);
}
.card-img-top{
    transition: transform .4s;
}

.card-img-top:hover {
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
    opacity: 0.5;
    
  }
.testimonials-container{
    width:20%;
    height:45vh;
    margin:25px;
    position: relative;
    overflow: hidden;
    background-color:white;
    box-shadow: 3px 3px 10px 5px grey;
}
.test-bg img{
    width:100%;
    height:100%;
}
.name-container{
    width:100%;
    height:25%;
    border: 3px solid rgb(240, 238, 238);
    padding:6px;
    overflow: hidden;
    border:5px solid green;
}
.testimonials-symbol{
    width:20%;
    height:100%;
    border:3px solid rgb(95, 138, 67);
    padding:3px;
}
.child-symbol{
    width:100%;
    height:100%;
    border:3px solid rgb(95, 138, 67);
    background: #98a86d;
}
.name-heading{
    padding:2px;
}
.name-heading h5{
    font-weight:100;
    font-size:16px;
    color:black;
    margin: 7px 10px 5px 20px;
}
.testimonilas-image img {
    height:100%;
    width:100%;
    display: block;
    overflow: hidden;
}
.message-container{
    width:100%;
    height:75%;
    border: 3px solid rgb(240, 238, 238);
    overflow: hidden;
    padding:10px;
    border:5px solid green;
}
.message-container h5{
    font-weight:100;
    font-size:16px;
    color:black;
    margin: 7px 10px 5px 20px;
    letter-spacing: 0.8px;
    word-spacing: 2px;
    text-align: unset;
}
.testimonilas-image{
    transition: transform .4s;
}

.testimonilas-image:hover {
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
}
.testimonials-container{
    transition: transform .4s;
}
.testimonials-container:hover {
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.1); 
    
  }

/*
*  Research and Event file css
*/

.research-and-event-container{
    width:100vw;
    height:80vh;
}
.research-and-event-inner-container{
    width:100%;
    height:80%;
}
.research-container{
    width: 50%;
    height:100%;
    padding:5px 5% 5px 5%;
    border-right: 2px solid rgb(146, 143, 143)
}
.event-container{
    width:50%;
    height:100%;
    padding:5px 5% 5px 5%;
    position: relative;
}
.event-component-container{
    width:100%;
    height: 27%;
    border: 1px solid whitesmoke;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    margin-right:10px;
    margin-bottom: 5px;
}
.event-component-container-project{
    width:100%;
    height: 112px;
    border: 1px solid whitesmoke;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    margin-right:10px;
    margin-bottom: 5px;
    position: relative;
}
.event-component-container-project:hover{
    background: #cccccc;
}
.event-image-container{
    width:30%;
    height:100%;
    position: relative;
}
.event-image-container img{
    height:100%;
    width:100%;
}
.event-summary-container{
    width:70%;
    height:100%;
    padding: 1% 5% 1% 5%;
}
.event-discription-container{
    height:30%;
    text-align: justify;
}
.popular-event-component-container{
    width:48%;
    height: 48%;
    border: 1px solid whitesmoke;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    margin-right:10px;
    flex-wrap: wrap;
}
.news-event-update-container{
    height:65vh;
    /* background: whitesmoke; */
}

.Certifications-container{
    height:40vh;
    /* background: whitesmoke; */
}

.news-content-container{
    height:72%;
    padding-left:2%;
    max-width: 100%;
    overflow-x: auto;

}
.news-content-container .card {
    height: 100%;
    min-width: 260px;  
}
.news-content-container .card .card-img-top {
    height:70%;
}
.popular-products{
    height:80vh;
    width:100%;
    flex-wrap: wrap;
    padding:0 2% 4% 2%;
}
.popular-products-container{
    height:100%;
    width:100%;
    flex-wrap: wrap;
    padding:1% 1% 5% 3%;
}
.popular-products-container .event-component-container{
    width:50%;
    height: 45%;
    border: 1px solid whitesmoke;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    margin:0;
}

.container-slider {
    position: relative;
    /* margin: 0 auto; */
  }
  
  .container-slider .content-slider {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
  }
  /* Darker background on mouse-over */
  .btn:hover {
    background-color: #196E3E;
  }
  .mobile-testimonials-container{
    display: none;
}
.testimonials-content-container{
    display: flex;
    padding:3%;
  }
  .testimonials-outer-container{
    height:80vh;
    position:relative
  }
  .test-bg{
    position:absolute;
    width:100%;
    height:100%;
  }
  .test-bg img{
      display: block;
    width:100%;
    height:100%;
  }

  @media only screen and (max-width: 600px) {
      body{
          overflow-x: hidden;
      }
      .carousel-inner{
          display: none;
      }
      .carousel{
          width:100vw;
          height: 50vh;
      }
      .carousel img{
          width:100%;
          height:100%;
      }
      .about-service-container{
        width:100%;
        height:auto;
    }
    .star-container{
        width:10%;
        height:20px;
        position: absolute;
        left:45%;
        top:38px;
    }
    .line-container_1{
        width:35%;
        position: absolute;
        left:40px;
        top:48px;
    }
    .line-container_2{
        width:35%;
        position: absolute;
        right:40px;
        top:48px;
    }
    .star{
        width:20%;
        height:65%;
        margin:10%;
        border-radius:45%;
    }
      .service-heading{
          width:100%;
          text-align: center;
          position: relative;
          left:2%;
          height:40%;
          color:grey;
          padding-top: 10px;
          margin-top: 10px;
      }
      .service-para-container{
        width:100%;
        height:10%;
        padding-left:2%;
        padding-right:2%;
        margin-top:20px;
    }
    .service-para{
        height:auto;
        width:100%;
        font-size: 14px;
        color:rgb(75, 74, 74) ;
        font-weight: 100;  
        text-align: justify;
        letter-spacing: 0.8px;
        word-spacing: 2px;
    }
    .mobile-homepage{
        display: block;
    }
    .container-heading{
        width: 100%;
        position: relative;
        left:2%;
        height:50px;
        padding-top: 10px;
    }
    .feature-type{
        width:98%;
        height:14%;
        float: none;
        margin-bottom: 10px;
        padding:15px 20px 15px 10px;
    }
    .feature-type .card{
        height:300px;
        overflow: visible;
        margin-top:0;
    }
    .feature-type .card .card-image{
        overflow: visible;
        width:100%;
        height:80%;
        margin-top:0;
        box-shadow: 3px 3px 10px 5px grey;
    }
    .feature-type .card .card-body{
        width:100%;
        height:20%;
    }
    .card-title{
        width: 100%;
        height:30%;
    }

    .product-and-service-container{
        height:auto;
        overflow: visible;
    }
    /* .product-container{
        height:auto;
        overflow: visible;
        display: block;
    } */
    /*
        * research and project css
    */

    .research-and-event-container{
        height:auto;
        border: 1px solid red;
        position: relative;
    }
    .research-and-event-inner-container{
        flex-wrap: wrap;
        width:100%;
        height:auto;
        display: block;
        position: relative;
    }
    .research-container{
        width:100%;
        height: auto;
    }
    .event-container{
        width:100%;
        height:auto;
    }
    .event-component-container{
        width:100%;
        height: 150px;
        margin-bottom:10px;
    }
    .event-component-container-project{
        width:100%;
        height: 150px;
        margin-bottom:10px;
    }

    /**
     `* testimonials css
    */
    .testimonials-outer-container{
        height:80vh;
        width:100%;
        display: block;
        position: relative;
        padding:3% 5% 3% 3%;
    }
    .testimonials-content-container{
      display: none;
    }
    .testimonials-container{
       display: none;
    }
    .event-image-container{
        height:50%;
        margin-top: 25px;
    }
    .mobile-testimonials-container{
        display: block;
        height:auto;
        width:100%;
        background: white;
        position: relative;
        top:20px;
    }
    .mobile-testimonials-content{
        height:15vh;
        border: 1px solid green;
        width:100%;
        padding:7px;
    }
    .test-bg{
        display: none;
    }
    .news-event-update-container{
        position: relative;
    }
    .our-research-img{
        margin-top: 25px;
    }
    .isoflexcon{
        flex-wrap: wrap;
    }
    .iso-test2{
        width: 150px;
        margin-top: 5%;
    }
    .Certifications-container{
        height:95vh;
    }
    .infra1-container{
        display: grid;
        grid-template-columns: 30% 70%;
        margin: 5px;
    }
    .infra1-container img{
        width: 28vh;
        height: 20vh;
    }
    .infra1-container div{
        font-size: 7px;
        text-align: center;
        vertical-align: middle;
        line-height: 100px;
    }
    .infra2-container{
        display: grid;
        grid-template-columns: 30% 70%;
        margin: 5px;
    }
    .infra2-container img{
        width: 28vh;
        height: 20vh;
    }
    .infra2-container div{
        font-size: 7px;
        text-align: right;
        vertical-align: middle;
        line-height: 100px;
    }
  }